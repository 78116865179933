import * as React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { navigate } from "gatsby"
import { Container, Button, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"



function ToursList({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query TourListings{
            allGraphCmsPageTours {
                edges {
                  node {
                    id
                    pageTitle
                    pageHeaderText {
                      html
                      markdown
                      raw
                      text
                    }
                    headerImage {
                        id
                        imageAltText
                       
                        galleryImage {
                          gatsbyImageData(placeholder: BLURRED, quality: 60)
                          url
                        }
                      
                    }
            
             
                    
                    
                    
                  }
                }
              }

              allGraphCmsTours {
                edges {
                  node {
                    id
                    tourTitle
                    tourStatus
                  
                    displayPrice
                    tourLocation
                    slug
                    tourPrice{
                      html
                      text
                      raw
                    }
                    tourDescription {
                      html
                      text
                      raw
                    }
                    tourImage {
                      id
                      imageAltText
                      
                      galleryImage {
                        gatsbyImageData(placeholder: BLURRED, quality: 60)
                      }
                    }
                  }
                }
              }
          }
        `}


    render={data => (   
            <div className="hotel-listings">
               <div class="back-main">
                                
                              
                               
                              
                        </div>
                <div className="header">
                {data.allGraphCmsPageTours.edges.map(({ node: hotel }) => (
                <div className="header-image">
                <div className="header-overlay"></div>
                  <img src={hotel.headerImage.galleryImage.url}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={hotel.headerImage.imageAltText}
                    />
                    <div className="header-text">
                        <h1>{hotel.pageTitle}</h1>
                    </div>
                
                </div> 
                ))}

                </div>
                
                <div className="tours">
      <div className="welcome-bg">

      </div>
      <div className="wrapper">

      <div className="gallery">
        
          <div className="image-gallery">
          
            <div className="photos">
           {data.allGraphCmsTours .edges.map(({ node: country }) => (
            <Link  to={`/${country.slug}`}>
              <div  key={country.id}>
            
               
                 <div className="main-content"  key={country.id}>
                      <div className="data-wrapper">
                      
                          <div className="estate-type">
                              <div className="image-field">
                                  <GatsbyImage image={country.tourImage.galleryImage.gatsbyImageData}
                                      quality={60}
                                      formats={["auto", "webp", "avif", "png", "jpg"]}
                                      alt={country.tourImage.imageAltText}
                                  />
                                
                                <div className="overlay">
                                <div className="overlay-2"></div>
                                      <div className="book">
                                        <a href="">View Tour</a>
                                      </div>
                                  <div className="content">
                                      <Container> 
                                      <div className="block">
                                             
                                      <div className="desc"> <p>{country.displayPrice}</p>
                                          <h2>{country.tourTitle}</h2>
                                     
                                        <div dangerouslySetInnerHTML={{ __html:country.tourDescription.html}} />
                                       
                                       
                                        </div>       
                                         
                                     </div>
                                      </Container>
                                  </div>
                                  
                                  
                                  </div>


                              </div>
                              {/*<div className="desc-field">
                                  <div className="content">
                                      <Container> 
                                      <div className="block">
                                             
                                        <div><h2>{country.tourTitle}</h2></div>
                                      
                                                
                                         
                                     </div>
                                      </Container>
                                  </div>
           </div>*/}
                          </div> 
                   

                      </div>
                      
                  </div> 
                
              </div>
              </Link>
              ))}
              </div>
          
          </div>
         </div>
      </div>
     
</div>





            </div>
      )}
    />
  );
}

export default ToursList;



